import SplitType from 'split-type';

/**
 * Adds a
 * @param target HTMLElement|string
 * @return void
 */
export default function spyScrollTitle ( target ) {
	if (typeof target === 'string' || target instanceof String) {
		target = document.querySelector( target );
	}

	if (target === null) {
		console.log( 'No target found' );
		return;
	}

	//IntersectionObserver
	const observer = new IntersectionObserver( ( entries ) => {
		entries.forEach( ( entry ) => {
			if (entry.isIntersecting && entry.intersectionRatio > 0.75) {
				let intersectionTarget = entry.target;

				//Check if the target already is animating
				if(intersectionTarget.querySelectorAll('.line-inner').length > 0) {
					return;
				}

				const split = new SplitType( intersectionTarget, {
					types: 'words, lines',
					lineClass: 'line',
				} ).lines;


				// $( '.line' ).wrapInner( `<div class='line-inner'></div>` );

				intersectionTarget.querySelectorAll( '.line' ).forEach( ( el, i ) => {
					setTimeout( function () {
						el.classList.add( 'active' );
					}, i * 300 );
				} );
				observer.unobserve(target);
			}
		} );
	}, {
		threshold: [ 0.75 ]
	} );

	observer.observe( target );
}
