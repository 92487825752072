export class BgScroll {
	constructor ( element, rootMargin ) {
		if (element === null || element.dataset.bgScroll === undefined) {
			return;
		}

		this.element = element;
		this.sections = this.element.querySelectorAll( 'section' );
		this.observeSection = this.observeSection.bind( this );
		this.rootMargin = element.dataset.rootMargin || '-50% 0% -100% 0%';
		this.init();
	}

	observeSection ( entries ) {
		entries.forEach( entry => {
			if (entry.isIntersecting) {
				// console.log( entry.target.dataset );
				// Remove all bg classes
				this.element.classList.forEach( className => {
					if (className.startsWith( 'bg-' )) {
						this.element.classList.remove( className );
					}
				} );
				// Add the proper bg class
				if (entry.target.dataset.bgClass !== undefined) {
					this.element.classList.add( entry.target.dataset.bgClass );
				}
			}
		} );
	}

	init () {
		const observer = new IntersectionObserver( this.observeSection, { rootMargin: this.rootMargin } );
		this.sections.forEach( section => {
			observer.observe( section );
		} );
	}
}
