import 'assets/styles/app.scss';
import { BgScroll } from './bg-scroll';
import spyScrollTitle from './spyScrollTitle';

const loadedComponents = [];

document.addEventListener( 'DOMContentLoaded', async () => {
	/**
	 * Bootstrap the components
	 */
	for (const dataComponent of document.querySelectorAll( '[data-component]' )) {
		let componentName = dataComponent.dataset.component;

		if (loadedComponents.indexOf( componentName ) === -1) {
			loadedComponents.push( componentName );

			try {
				let component = await import(
					/* WebpackMode: "lazy" */
					/* webpackPrefetch: true */
					/* webpackPreload: true */
					`components/${componentName}/${componentName}.js`
					);

				component.default();
			} catch (error) {
				console.error( 'Loading error: ', error );
			}
		}
	}

	const el = document.querySelector('[data-bg-scroll]');
	new BgScroll(el);

	/**
	 * Offerte aanvragen script
	 */
	$( '.form-switch a' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '.form-switch a' ).removeClass( 'active' );
		$( this ).addClass( 'active' );

		const id = $( this ).attr( 'href' );
		$( '.connect' ).hide();
		$( id ).show();

	} );

	$( '.collaboration__list ul li a' ).on( 'mouseenter', function ( event ) {
		const imageSrc = $( this ).attr( 'data-img-src' );
		const image = $( '#hover-overlay-img' );

		image.attr( 'src', imageSrc );
		$( '.hover-overlay' ).css( 'opacity', '1' );
	} ).on( 'mouseleave', function ( event ) {
		$( '.hover-overlay' ).css( 'opacity', '0' );
	} );

	$( '#locationToDeliverChicksInput' ).on( 'change', function ( e ) {
		//console.log( $( this ).val() );

		if ($( '#locationToDeliverChicks' ).length) {
			$( '#locationToDeliverChicks' ).val( $( this ).val() );
		}
	} );

	$( '#locationToDeliverEggsInput' ).on( 'change', function ( e ) {
		//console.log( $( this ).val() );

		if ($( '#locationToDeliverEggs' ).length) {
			$( '#locationToDeliverEggs' ).val( $( this ).val() );
		}
	} );

	$( '#numberOfEggsInput' ).on( 'change', function ( e ) {
		//console.log( $( this ).val() );

		if ($( '#numberOfEggs' ).length) {
			$( '#numberOfEggs' ).val( $( this ).val() );
		}
	} );

	$( '#numberOfChicksInput' ).on( 'change', function ( e ) {
		//console.log( $( this ).val() );

		if ($( '#numberOfChicks' ).length) {
			$( '#numberOfChicks' ).val( $( this ).val() );
		}
	} );

	document.querySelectorAll('[data-animated-title]').forEach((element) => {
		spyScrollTitle(element);
	});

	/* Sticky Nav */
	if (document.querySelector('.call-action__menu') === null) {
		return;
	}

	const slugify = str => {
		return str.toLowerCase()
			.trim()
			.replace(/[^\w\s-]/g, '')
			.replace(/[\s_-]+/g, '-')
			.replace(/^-+|-+$/g, '');
	}

	const url = location.protocol + '//' + location.host + location.pathname;
	const arrItems = document.querySelectorAll('[data-sticky-title]');
	const arrElements = [];
	arrItems.forEach((node) => {
		let title = node.getAttribute('data-sticky-title');
		let id = node.id = slugify(title);
		node.setAttribute('data-magellan-target', id);
		arrElements.push(`<li><a href="${url}#${id}" class="js-scroll">${title}</a></li>`)
		//console.log(title);
	});

	document.querySelector('ul.call-action__menu').innerHTML = arrElements.join('');
	//document.querySelector('ul.call-action__menu li:first-child').classList.add("active"); // Add active class to first child


	$(function() {
		$('.call-action__menu li').click(function(e) {
			//e.preventDefault();
			$('.call-action__menu li').removeClass('is-active');
			$(this).addClass('is-active');
		});

		// Don't execute on mobile.
		if($(window).width() >= 768) {
			$('main.sticky-nav section').each(function (index) {
				let elem = $(this);
				window.addEventListener('scroll', function () {
					let position = elem[0].getBoundingClientRect();

					if (position.top + 200  < window.innerHeight && position.bottom >= 0) {
						$('.call-action__menu li').removeClass('is-active');
						$('.call-action__menu li').each(function () {
							let activeLi = $(this).find('a').attr("href").substr($(this).find('a').attr("href").indexOf("#") + 1);
							if (activeLi === elem.attr("id")) {
								$(this).addClass('is-active');
							}
						});
					}
				});
			});
		}
	});
	/* Sticky Nav */

	$(window).scroll(function() {
		let height = $(window).scrollTop();
		if (height > 0) {
			$(".call-action").addClass("scrolled");
		} else {
			$('.call-action').removeClass('scrolled');
		}
	});
} );
